import { cn } from '@/lib/utils'

type ContentContainerProps = {
  children: React.ReactNode
  className?: string
  id?: string
}

const ContentContainer = ({ children, className, ...props }: ContentContainerProps) => {
  return (
    <main className={cn('relative z-content', className)} {...props}>
      {children}
    </main>
  )
}

export default ContentContainer
