import useStore from '@/store'

type AudioPlayerWrapperProps = {
  audioUrl: string
  children?: React.ReactNode
}

const AudioPlayerWrapper = ({ audioUrl, children }: AudioPlayerWrapperProps) => {
  const setAudioPlayerOpened = useStore((state) => state.setAudioPlayerOpened)

  const toggleShowingHandler = () => {
    setAudioPlayerOpened(true, audioUrl)
  }

  return <button onClick={toggleShowingHandler}>{children}</button>
}

export default AudioPlayerWrapper
