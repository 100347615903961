import AudioPlayerWrapper from '@/components/modules/audio-player/audio-player-wrapper'
import { cn } from '@/lib/utils'
import type { File } from '@/types'

export type ReadAndListenProps = {
  readingTime?: string
  storyAudio?: File
  className?: string
}

const ReadAndListen = ({ readingTime, storyAudio, className }: ReadAndListenProps) => (
  <div className={cn('w-full text-center', className)}>
    {readingTime && <p className="field-name mb-2.5">{readingTime}</p>}
    {storyAudio && (
      <AudioPlayerWrapper audioUrl={storyAudio.url}>
        <div className="nav-text-small flex justify-center gap-2.5">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6979 11.6257C15.4075 11.6257 15.1667 11.3848 15.1667 11.0944V7.55273C15.1667 5.88815 14.4513 4.2944 13.1975 3.18232C12.135 2.23315 10.7538 1.70898 9.32292 1.70898H7.90625C6.47542 1.70898 5.09417 2.23315 4.03167 3.18232C2.77792 4.2944 2.0625 5.88815 2.0625 7.55273V11.0944C2.0625 11.3848 1.82167 11.6257 1.53125 11.6257C1.24083 11.6257 1 11.3848 1 11.0944V7.55273C1 5.58357 1.85 3.6994 3.32333 2.38898C4.58417 1.26982 6.21333 0.646484 7.90625 0.646484H9.32292C11.0158 0.646484 12.645 1.26273 13.9058 2.38898C15.3792 3.6994 16.2292 5.58357 16.2292 7.55273V11.0944C16.2292 11.3848 15.9883 11.6257 15.6979 11.6257Z"
              fill="currentColor"
            />
            <path
              d="M12.867 3.84815C12.6332 3.84815 12.3853 3.77732 12.1799 3.64273C11.3299 3.06898 10.3453 2.77148 9.32533 2.77148H7.90866C6.88866 2.77148 5.90408 3.06898 5.06116 3.63565C4.48741 4.01107 3.72241 3.85523 3.33991 3.29565C3.25491 3.1894 3.19825 3.03357 3.16991 2.9344C3.11325 2.73607 3.16991 2.52357 3.32575 2.38898C4.58658 1.26273 6.21575 0.646484 7.90866 0.646484H9.32533C11.0182 0.646484 12.6474 1.26273 13.9082 2.38898C14.0641 2.52357 14.1207 2.73607 14.0641 2.9344C14.0357 3.03357 13.9862 3.1894 13.8728 3.32398C13.6674 3.63565 13.2849 3.84815 12.867 3.84815Z"
              fill="currentColor"
            />
            <path
              d="M3.66146 14.4577H2.59896C1.91896 14.4577 1.35938 13.8981 1.35938 13.2181V10.0306C1.35938 9.3506 1.91896 8.79102 2.59896 8.79102H3.66146C3.95187 8.79102 4.19271 9.03185 4.19271 9.32227V13.9264C4.19271 14.2168 3.95187 14.4577 3.66146 14.4577ZM2.59896 9.85352C2.49979 9.85352 2.42188 9.93143 2.42188 10.0306V13.2181C2.42188 13.3173 2.49979 13.3952 2.59896 13.3952H3.13021V9.85352H2.59896Z"
              fill="currentColor"
            />
            <path
              d="M13.5781 14.4577H14.6406C15.3206 14.4577 15.8802 13.8981 15.8802 13.2181V10.0306C15.8802 9.3506 15.3206 8.79102 14.6406 8.79102H13.5781C13.2877 8.79102 13.0469 9.03185 13.0469 9.32227V13.9264C13.0469 14.2168 13.2877 14.4577 13.5781 14.4577ZM14.6406 9.85352C14.7398 9.85352 14.8177 9.93143 14.8177 10.0306V13.2181C14.8177 13.3173 14.7398 13.3952 14.6406 13.3952H14.1094V9.85352H14.6406Z"
              fill="currentColor"
            />
            <path
              d="M5.42708 8.08398H4.71875C3.83855 8.08398 3.125 8.79753 3.125 9.67773V13.5736C3.125 14.4538 3.83855 15.1673 4.71875 15.1673H5.42708C6.30729 15.1673 7.02083 14.4538 7.02083 13.5736V9.67773C7.02083 8.79753 6.30729 8.08398 5.42708 8.08398Z"
              fill="currentColor"
            />
            <path
              d="M12.513 8.08398H11.8047C10.9245 8.08398 10.2109 8.79753 10.2109 9.67773V13.5736C10.2109 14.4538 10.9245 15.1673 11.8047 15.1673H12.513C13.3932 15.1673 14.1068 14.4538 14.1068 13.5736V9.67773C14.1068 8.79753 13.3932 8.08398 12.513 8.08398Z"
              fill="currentColor"
            />
          </svg>
          Listen to this story
        </div>
      </AudioPlayerWrapper>
    )}
  </div>
)

export default ReadAndListen
