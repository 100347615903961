import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

export type MediaCaptionVariantProps = VariantProps<typeof mediaCaptionVariants>
export const mediaCaptionVariants = cva('caption inline h-auto w-auto py-1', {
  variants: {
    position: {
      top: 'bottom-full',
      bottom: 'top-full',
      left: 'bottom-0 right-full origin-bottom-left -translate-y-full -rotate-90',
      right: 'bottom-0 left-full rotate-180 py-0 [writing-mode:vertical-rl]',
    },
  },
  compoundVariants: [
    {
      position: ['top', 'left', 'right', 'bottom'],
      class: 'absolute inset-auto',
    },
  ],
  defaultVariants: {
    position: 'bottom',
  },
})

export interface MediaCaptionProps extends MediaCaptionVariantProps {
  className?: string
  normal?: string
  bold?: string
}

const MediaCaption = ({ className, normal, bold, position }: MediaCaptionProps) => {
  if (!normal && !bold) return null

  return (
    <p className={cn(mediaCaptionVariants({ position }), className)}>
      {!!bold && <strong className="hidden md:inline">{bold}</strong>} {!!normal && normal}
    </p>
  )
}

export default MediaCaption
