'use client'

import { useEffect, useRef } from 'react'

import useIsOnscreen from '@/hooks/use-is-onscreen'
import useStore from '@/store'

type TransparentHeaderContainerProps = {
  children: React.ReactNode
  className?: string,
  rootMargin?: string
}

const TransparentHeaderContainer = ({
  children,
  className,
  rootMargin,
  ...props
}: TransparentHeaderContainerProps) => {
  const ref = useRef(null)
  const isVisible = useIsOnscreen({
    ref,
    rootMargin: rootMargin ?? '0px 0px -98% 0px',
    once: false,
  })

  const setIsHeaderTransparent = useStore((state) => state.setIsHeaderTransparent)

  useEffect(() => {
    setIsHeaderTransparent(isVisible)
  }, [isVisible, setIsHeaderTransparent])

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}

export default TransparentHeaderContainer
