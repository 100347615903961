import { cva, VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import type { Image } from 'sanity'

import { cn } from '@/lib/utils'
import type { Media, MediaOnly } from '@/types'

import MediaCaption from './media-caption'
import MuxVideo from './mux-video'
import { SanityImage } from './sanity-image'

export type MediaVariantProps = VariantProps<typeof mediaVariants>
export const mediaVariants = cva('before:block', {
  variants: {
    aspectRatio: {
      square: 'before:aspect-square',
      '16/9': 'before:aspect-h-9 before:aspect-w-16',
      '7/5': 'before:aspect-h-5 before:aspect-w-7',
      '4/3': 'before:aspect-h-3 before:aspect-w-4',
      portrait: 'before:aspect-h-13 before:aspect-w-10',
      wide: 'before:aspect-h-9 before:aspect-w-16 lg:before:aspect-h-4 lg:before:aspect-w-10',
      default: 'h-full w-full',
      native: '',
    },
    cover: {
      true: 'h-full w-full',
    },
    border: {
      none: '',
      border: 'border-2 border-current p-1.5',
      'border-padding': 'border-[3px] border-current p-[3px]',
      'border-thin-padding': 'border-[1px] border-current p-2',
      'double-border':
        'before:absolute before:bottom-0 before:left-[-4px] before:right-[-4px] before:top-0 before:w-[calc(100%+8px)] before:border-x after:absolute after:bottom-[-4px] after:left-0 after:right-0 after:top-[-4px] after:border-y',
      'double-border-top':
        'before:absolute before:bottom-[-4px] before:left-[-8px] before:right-[-8px] before:top-[-4px] before:w-[calc(100%+16px)] before:border-x after:absolute after:bottom-[-8px] after:left-[-4px] after:right-[-4px] after:top-[-8px] after:border-y',
      rounded: 'overflow-hidden rounded-[20px] border-2 border-current',
      'border-without-padding': 'border-2 p-0',
      'border-3px': 'border-[3px]',
      'rounded-without-border': 'overflow-hidden rounded-[20px]',

      // gallery
      '3-lines-border':
        'border border-current p-[10px] before:absolute before:left-[5px] before:top-[5px] before:h-[calc(100%-10px)] before:w-[calc(100%-10px)] before:border before:border-current after:absolute after:left-[10px] after:top-[10px] after:h-[calc(100%-20px)] after:w-[calc(100%-20px)] after:border after:border-current',
      '1-line-border': 'border border-current p-1',
      'overlap-side-border':
        'p-2 before:absolute before:left-[8px] before:top-0 before:h-full before:w-[calc(100%-16px)] before:border-y-[4px] before:border-current after:absolute after:left-0 after:top-0 after:h-full after:w-full after:border-x-[4px] after:border-current',
      'border-padding-lg': 'border-[3px] border-current p-3',
      'inside-border':
        'bg-beige p-2 before:absolute before:left-0 before:top-[5px] before:h-[calc(100%-10px)] before:w-full before:border-y-[2px] before:border-[#353839] after:absolute after:left-0 after:top-[1px] after:h-[calc(100%-2px)] after:w-full after:border-y-[2px] after:border-[#353839]',
      '2-inside-border-x':
        'before:absolute before:left-[5px] before:top-[8px] before:h-[calc(100%-16px)] before:w-[calc(100%-10px)] before:border-x-[2px] before:border-[#353839] after:absolute after:left-[1px] after:top-[8px] after:h-[calc(100%-16px)] after:w-[calc(100%-2px)] after:border-x-[2px] after:border-[#353839]',
    },
  },
  defaultVariants: {
    aspectRatio: 'default',
  },
})

export interface MediaProps extends MediaVariantProps {
  data: Media | MediaOnly | undefined
  className?: string
  cover?: boolean
  captionClassName?: string
  captionOverride?: Media['caption']
  borderOverride?: Media['border']
  sizes?: string
}

type Ref = HTMLDivElement

const Media = forwardRef<Ref, MediaProps>(
  (
    {
      data,
      className,
      cover,
      aspectRatio: aspectRatioOverride,
      captionClassName,
      captionOverride,
      borderOverride,
      sizes,
    },
    ref,
  ) => {
    if (!data || data.mediaType === 'none') {
      return null
    }

    const { mediaType, image, video, _type, thumbnailTime } = data
    const caption = captionOverride || (data as Media)?.caption || undefined
    const border = borderOverride || (data as Media)?.border || 'none'
    const nativeAspectRatio = (data as Media)?.nativeAspectRatio
    const aspectRatio = aspectRatioOverride || (data as Media)?.aspectRatio || 'default'

    const wrapperClasses = cn('relative w-full', mediaVariants({ className, cover }), className)

    const nativeAspectRatioDestructured = nativeAspectRatio && nativeAspectRatio.split(':')
    const nativeAspectRatioValue =
      nativeAspectRatioDestructured &&
      (parseInt(nativeAspectRatioDestructured[0]) / parseInt(nativeAspectRatioDestructured[1])) *
        100

    return (
      <div className={wrapperClasses} ref={ref}>
        <div
          style={
            {
              '--native-aspect-ratio': `${nativeAspectRatioValue}%`,
            } as React.CSSProperties
          }
          className={cn(
            'relative',
            {
              [`before:block before:pt-[var(--native-aspect-ratio)]`]: aspectRatio === 'native',
            },
            mediaVariants({ aspectRatio }),
          )}
        >
          <div className={cn('absolute inset-0 h-full w-full', mediaVariants({ border }))}>
            <div className="media-preview" />
            {mediaType === 'image' && image && (
              <SanityImage
                data={image as Image}
                className="h-full w-full object-cover"
                sizes={sizes}
              />
            )}
            {mediaType === 'video' && video?.asset && (
              <MuxVideo
                thumbnailTime={thumbnailTime}
                className="h-full w-full object-cover"
                {...video.asset}
              />
            )}
            {border === 'inside-border' && (
              <div
                className={cn(
                  'absolute inset-0 h-full w-full',
                  mediaVariants({ border: '2-inside-border-x' }),
                )}
              />
            )}
            {border === 'double-border' && (
              <div
                className={cn(
                  'absolute inset-0 h-full w-full',
                  mediaVariants({ border: 'double-border-top' }),
                )}
              />
            )}
          </div>
        </div>
        {!!(_type === 'media' && caption) && (
          <MediaCaption {...caption} className={captionClassName} />
        )}
      </div>
    )
  },
)

Media.displayName = 'Media'

export default Media
