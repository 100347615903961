'use client'
import MuxPlayer from '@mux/mux-player-react/lazy'
// import MuxPlayer from '@mux/mux-player-react'
import { forwardRef } from 'react'

// import { generateBlurUp } from '@/lib/utils.server'

type MuxVideoProps = {
  playbackId?: string
  title?: string
  className?: string
  autoPlay?: boolean
  isMuted?: boolean
  onPlay?: (event) => void
  thumbnailTime?: number
}

export type MuxVideoRef = HTMLVideoElement | null

const MuxVideo = forwardRef<MuxVideoRef, MuxVideoProps>(
  (
    { thumbnailTime, playbackId, title, className, autoPlay = true, isMuted = true, ...props },
    ref,
  ) => {
    // Forward the nativeEl to the parent ref
    const setRef = (muxPlayerEl) => {
      const nativeEl = muxPlayerEl?.media?.nativeEl as HTMLVideoElement

      if (ref) {
        if (typeof ref === 'function') {
          ref(nativeEl)
        } else if ('current' in ref) {
          ref.current = nativeEl
        }
      }
    }

    if (!playbackId) return null

    // const placeholder = await generateBlurUp(playbackId)

    return (
      <MuxPlayer
        loading="viewport"
        className={className}
        playbackId={playbackId}
        ref={setRef}
        // metadata={title ? { video_title: title } : undefined}
        // style={{ aspectRatio: aspectRatio }}
        // placeholder={placeholder || ''}
        style={{
          ['--controls' as string]: 'none',
          ['--media-object-fit' as string]: 'cover',
          ['--media-object-position' as string]: 'center',
        }}
        streamType="on-demand"
        autoPlay={autoPlay ? 'muted' : false}
        muted={isMuted}
        loop={true}
        thumbnailTime={thumbnailTime || 0}
        onEnded={(evt) => {
          const videoEl = evt.target as HTMLVideoElement
          videoEl.play()
        }}
        {...props}
      />
    )
  },
)

MuxVideo.displayName = 'MuxVideo'

export default MuxVideo
