// import type { Home, HomePageQueryResult, StoreIntro as StoreIntroType } from '@/sanity/types'
import dynamic from 'next/dynamic'

import type { Content, EventContent, MythContent, PostContent } from '@/types'

const ContactListing = dynamic(() => import('@/components/modules/contact-listing'))
const FAQlisting = dynamic(() => import('@/components/faq/faq-listing'))
const AccordionBlock = dynamic(() => import('./accordion-block'))
const AwardsAndAchievements = dynamic(
  () => import('./awards-and-achievements/awards-and-achievements'),
)
const BlogDivider = dynamic(() => import('./blog-divider'))
const BlogMedia = dynamic(() => import('./blog-media'))
const CafeList = dynamic(() => import('./cafe-list'))
const CareersModule = dynamic(() => import('./careers-module'))
const CollectionCta = dynamic(() => import('./collection-cta'))
const CollectionSlider = dynamic(() => import('./collection-slider'))
const ContentBucket = dynamic(() => import('./content-bucket'))
const CtaBanner = dynamic(() => import('./cta-banner'))
const CuratedRow = dynamic(() => import('./curated-row'))
const FeatureAndList = dynamic(() => import('./feature-and-list'))
const FeaturedPostBlock = dynamic(() => import('./featured-post-block'))
const Form = dynamic(() => import('./form'))
const FoundingMythsScroller = dynamic(() => import('./founding-myths-scroller'))
const FoundingMythsSlider = dynamic(() => import('./founding-myths-slider'))
const FullWidthFeature = dynamic(() => import('./full-width-feature'))
const FullWidthGuideFeature = dynamic(() => import('./full-width-guide-feature'))
const FullWidthMood = dynamic(() => import('./full-width-mood'))
const Gallery = dynamic(() => import('./gallery'))
const Gallery2up3up = dynamic(() => import('./gallery-2up-3up'))
const GallerySlider = dynamic(() => import('./gallery-slider'))
const HeroCta = dynamic(() => import('./hero-cta'))
const ImpactModule = dynamic(() => import('./impact-module'))
const Intro = dynamic(() => import('./intro'))
const KeyPropositions = dynamic(() => import('./key-propositions'))
const MapGuide = dynamic(() => import('./map-guide'))
const MethodAndIngredients = dynamic(
  () => import('./method-and-ingredients/method-and-ingredients'),
)
const NextChapter = dynamic(() => import('./next-chapter'))
const OriginStory = dynamic(() => import('./origin-story'))
const PermitRoomPromo = dynamic(() => import('./permit-room-promo'))
const PlaylistModule = dynamic(() => import('./playlist-module'))
const ProductGrid = dynamic(() => import('./product-grid'))
const ProductStory = dynamic(() => import('./product-story'))
const PullQuote = dynamic(() => import('./pull-quote'))
const PullQuoteSlider = dynamic(() => import('./pull-quote-slider'))
const RelatedGuideContent = dynamic(() => import('./related-guide-content'))
const Resources = dynamic(() => import('./resources'))
const SectionFullHeader = dynamic(() => import('./section-full-header'))
const SingleMediaCta = dynamic(() => import('./single-media-cta'))
const SocialCarousel = dynamic(() => import('./social-carousel'))
const StoreHero = dynamic(() => import('./store-hero'))
const StoreIntro = dynamic(() => import('./store-intro'))
const TextBlock = dynamic(() => import('./text-block'))
const TipsOverlay = dynamic(() => import('./tips-overlay'))
const VideosBlock = dynamic(() => import('./videos-block'))
const FeatureProductsBlock = dynamic(() => import('./feature-products-block'))
const CollectionPageGrid = dynamic(() => import('./collection-page-view/collection-page-grid'))
const LifestyleShoppingModule = dynamic(() => import('./lifestyle-shopping-module'))
const CollaboratorSpotlight = dynamic(() => import('./collaborator-spotlight'))
const CollectionsSpotlight = dynamic(() => import('./collections-spotlight'))
const PartnerCollection = dynamic(() => import('./partner-collection'))
const NewsletterCTA = dynamic(() => import('./newsletter/newsletter-cta'))
const CreateAtHomeCallout = dynamic(() => import('./create-at-home-callout'))
const SimpleTitle = dynamic(() => import('./simpleTitle'))
const TicketCard = dynamic(() => import('./ticket-card'))

// export type Content = NonNullable<HomePageQueryResult>['content']

type ContentProps = {
  data: Content | PostContent | MythContent | EventContent
  isMyth?: boolean
  isHub?: boolean
  isEvent?: boolean
  isGifting?: boolean
}

const Content = ({ data, isMyth, isHub, isEvent, isGifting }: ContentProps) => {
  if (!data) {
    return null
  }

  return data.map((block, key) => {
    switch (block._type) {
      case 'intro':
        return <Intro {...block} key={key} />
      case 'contactUs':
        return <ContactListing {...block} key={key} />
      case 'faq':
        return <FAQlisting {...block} key={key} />
      case 'keyPropositions':
        return <KeyPropositions {...block} key={key} />
      case 'cafeList':
        return <CafeList {...block} key={key} />
      case 'originStory':
        return <OriginStory {...block} key={key} />
      case 'sectionFullHeader':
        return <SectionFullHeader {...block} key={key} />
      case 'storeIntro':
        return <StoreIntro {...block} key={key} />
      case 'permitRoomPromo':
        return <PermitRoomPromo {...block} key={key} />
      case 'impactModule':
        return <ImpactModule {...block} key={key} />
      case 'careersModule':
        return <CareersModule {...block} key={key} />
      case 'awardsAndAchievements':
        return <AwardsAndAchievements {...block} key={key} />
      case 'foundingMythsSlider':
        return <FoundingMythsSlider {...block} key={key} />
      case 'gallerySlider':
        return <GallerySlider {...block} key={key} isMyth={isMyth} />
      case 'curatedRow':
        return <CuratedRow {...block} key={key} />
      case 'pullQuote':
        return <PullQuote {...block} key={key} />
      case 'pullQuoteSlider':
        return <PullQuoteSlider {...block} key={key} />
      case 'gallery2up3up':
        return <Gallery2up3up {...block} key={key} />
      case 'textBlock':
        return (
          <TextBlock
            {...block}
            key={key}
            isMyth={isMyth}
            isHub={isHub}
            isEvent={isEvent}
            isGifting={isGifting}
          />
        )
      case 'blogMedia':
        return <BlogMedia {...block} key={key} isMyth={isMyth} isEvent={isEvent} />
      case 'resources':
        return <Resources {...block} key={key} />
      case 'fullWidthFeature':
        return <FullWidthFeature {...block} key={key} />
      case 'nextChapter':
        return <NextChapter {...block} key={key} isMyth={isMyth} />
      case 'blogDivider':
        return <BlogDivider {...block} key={key} isMyth={isMyth} />
      case 'fullWidthMood':
        return <FullWidthMood {...block} key={key} />
      case 'gallery':
        return <Gallery {...block} key={key} isMyth={isMyth} />
      case 'productStoryBlock':
        return <ProductStory {...block} key={key} />
      case 'ctaBanner':
        return <CtaBanner {...block} key={key} />
      case 'heroCta':
        return <HeroCta {...block} key={key} />
      case 'methodAndIngredients':
        return <MethodAndIngredients {...block} key={key} />
      case 'contentBucket':
        return <ContentBucket {...block} key={key} />
      case 'featureAndList':
        return <FeatureAndList {...block} key={key} />
      case 'form':
        return <Form {...block} key={key} />
      case 'foundingMythsScroller':
        return <FoundingMythsScroller {...block} key={key} />
      case 'fullWidthGuideFeature':
        return <FullWidthGuideFeature {...block} key={key} />
      case 'videosBlock':
        return <VideosBlock {...block} key={key} />
      case 'mapGuide':
        return <MapGuide {...block} key={key} />
      case 'collectionCta':
        return <CollectionCta {...block} key={key} />
      case 'relatedGuideContent':
        return <RelatedGuideContent {...block} key={key} />
      case 'featuredPostBlock':
        return <FeaturedPostBlock {...block} key={key} />
      case 'singleMediaCta':
        return <SingleMediaCta {...block} key={key} />
      case 'accordion':
        return <AccordionBlock {...block} key={key} isEvent={isEvent} />
      case 'storeHero':
        return <StoreHero {...block} key={key} />
      case 'collectionSlider':
        return <CollectionSlider {...block} key={key} />
      case 'productGrid':
        return <ProductGrid {...block} key={key} />
      case 'socialCarousel':
        return <SocialCarousel {...block} key={key} />
      case 'playlistModule':
        return <PlaylistModule {...block} key={key} />
      case 'collectionPageGrid':
        return <CollectionPageGrid {...block} key={key} />
      case 'tipsOverlay':
        return <TipsOverlay {...block} key={key} />
      case 'featureProductsBlock':
        return <FeatureProductsBlock {...block} key={key} />
      case 'lifestyleShoppingModule':
        return <LifestyleShoppingModule {...block} key={key} />
      case 'collaboratorSpotlight':
        return <CollaboratorSpotlight {...block} key={key} />
      case 'collectionsSpotlight':
        return <CollectionsSpotlight {...block} key={key} />
      case 'partnerCollection':
        return <PartnerCollection {...block} key={key} />
      case 'newsletterCTA':
        return <NewsletterCTA {...block} key={key} />
      case 'createAtHomeCallout':
        return <CreateAtHomeCallout {...block} key={key} />
      case 'simpleTitle':
        return <SimpleTitle {...block} key={key} />
      case 'ticketCard':
        return <TicketCard {...block} key={key} />
      default:
        return null
    }
  })
}

export default Content
