import { getExtension, getImageDimensions } from '@sanity/asset-utils'
import NextImage from 'next/image'
import React from 'react'
import type { Image, ImageUrlBuilder } from 'sanity'

import { cn } from '@/lib/utils'
import { urlForImage } from '@/sanity/lib/utils'

const isDev = process.env.NODE_ENV === 'development'

export type SanityImageProps = {
  /**
   *
   * @example
   * ```
   * <SanityImage data={image} />
   * ```
   */
  className?: string
  sizes?: string
  data: Image
  priority?: boolean
  width?: number
  height?: number
} & React.ComponentPropsWithRef<'img'>

/**
 * Sanity’s Image component is a wrapper around the HTML image element.
 * It supports the same props as the HTML `img` element, but automatically
 * generates the srcSet and sizes attributes for you.
 *
 *
 */
const SanityImage = ({
  data,
  className,
  sizes,
  width,
  height,
  priority,
  style,
  ...passthroughProps
}: SanityImageProps) => {
  if (!data || !data?.asset?._ref) {
    return null
  }

  const _ref = data.asset._ref
  const extension = getExtension(_ref)
  const imageBuilder = urlForImage(data) as ImageUrlBuilder
  const { height: imageHeight, width: imageWidth } = getImageDimensions(_ref)
  const placeholderExcludedExtensions = ['svg', 'png'] // Don't use placeholder if the image is a PNG or SVG
  const focalCoords = !!(data.hotspot?.x && data.hotspot?.y) && {
    x: Math.ceil(data.hotspot.x * 100),
    y: Math.ceil(data.hotspot.y * 100),
  }

  const focalProperties =
    focalCoords &&
    ({
      objectPosition: `${focalCoords.x}% ${focalCoords.y}%`,
    } as React.CSSProperties)

  const url = imageBuilder.url()

  // // TODO: remove if we won't be using Sanity image sizes
  // // Values used for srcset attribute of image tag (in pixels)
  // const srcSetValues = [
  //   50, 100, 200, 450, 600, 750, 900, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 3500, 4000, 5000,
  // ]

  // // Create srcset attribute
  // const srcSet = srcSetValues
  //   .filter((value) => value < width)
  //   .map((value) => {
  //     const imageUrl = generateImageUrl({
  //       urlBuilder,
  //       width: value,
  //     })
  //     if (width >= value) {
  //       return `${imageUrl} ${value}w`
  //     }
  //     return ''
  //   })
  //   .join(', ')
  //   .concat(`, ${urlDefault} ${width}w`)

  // if (isDev && !sizes) {
  //   console.warn(
  //     [
  //       'No sizes prop provided to SanityImage component,',
  //       'you may be loading unnecessarily large images.',
  //       `Image used is ${url || data?.asset?._ref || 'unknown'}`,
  //     ].join(' '),
  //   )
  // }

  return (
    <NextImage
      src={url}
      width={width || imageWidth}
      height={height || imageHeight}
      style={
        {
          ...style,
          ...focalProperties,
        } as React.CSSProperties
      }
      className={cn('transition-all duration-300', className)}
      sizes={sizes}
      placeholder={
        data.lqip && !placeholderExcludedExtensions.includes(extension) ? 'blur' : 'empty'
      }
      blurDataURL={
        data.lqip && !placeholderExcludedExtensions.includes(extension)
          ? (data.lqip as string)
          : undefined
      }
      alt={(data?.altText as string) || ''}
      loading={priority ? 'eager' : 'lazy'}
      priority={priority ? priority : undefined}
      {...passthroughProps}
    />
  )
}

SanityImage.displayName = 'SanityImage'

export { SanityImage }
