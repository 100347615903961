/*
  Is a given element currently in the viewport?
  This hook tracks that information using
  IntersectionObserver.
*/
import { useEffect, useState } from 'react'

function useIsOnscreen({
  ref,
  defaultState = false,
  rootMargin = '0px 0px -15% 0px',
  once = true,
}) {
  const [isOnscreen, setIsOnscreen] = useState(defaultState)

  useEffect(() => {
    if (!ref.current) return

    const observer = new window.IntersectionObserver(
      (entries, observer) => {
        const [entry] = entries
        if (once) {
          if (entry.isIntersecting) {
            setIsOnscreen(true)
            observer.unobserve(entry.target)
          }
        } else {
          setIsOnscreen(entry.isIntersecting)
        }
      },
      { rootMargin: rootMargin },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref, once, rootMargin])

  return isOnscreen
}
export default useIsOnscreen
